/* ***** GLOBAL ***** */
body {
  overscroll-behavior: none !important;
}

.grid-container {
  width: 100%;
  overflow: hidden;
  @include breakpoint(1441px down) {
    padding-left: $global-padding!important;
    padding-right: $global-padding!important;
  }
  #showcase &,
  .react &,
  .bio &,
  .contact & {
    @include breakpoint(667px down) {
      padding-left: 0!important;
      padding-right: 0!important;
    }
  }
}

#showcase,
.react,
.bio,
.contact {
  @include breakpoint(small up) {
    padding-top: rem-calc($custom-space / 2);
    padding-bottom: rem-calc($custom-space / 2);
  }
  @include breakpoint(large up) {
    padding-top: rem-calc($l-custom-space / 2);
    padding-bottom: rem-calc($l-custom-space / 2);
  }
}

/* ***** Absolute link ***** */
.abs-link {
  @include absolute-center;
  width: 100%;
  height: 100%;
}

.socials {
  a {
    font-size: rem-calc(26);
  }
}
/* ***** Button ***** */
.button {
  display: inline-block;
  font-weight: bold;
  border-radius: $global-radius;
  @include transition-transform(all, 400ms, ease-in-out, 0s);
}

/* ***** Header ***** */
header {
  @include breakpoint(small up) {
    #showcase & {
      padding-top: rem-calc($custom-space / 2);
    }
    padding-bottom: rem-calc($custom-space * 2);
  }
  @include breakpoint(large up) {
    #showcase & {
      padding-top: rem-calc($l-custom-space / 2);
    }
    padding-bottom: $l-custom-space;
  }
}

/* ***** Card Item ***** */
.item {
  position: relative;
  margin-bottom: $global-margin;
  h4, p {
    @include absolute-center;
    width: 100%;
    padding: $global-padding;
    @include transition-transform(all, 400ms, ease-in-out, 0s);
    z-index: 1;
  }
}

/* ***** Article Content ***** */
.bio,
.react {
  article {
    @include breakpoint(small up) {
      // padding-top: rem-calc($custom-space / 2);
      padding-bottom: rem-calc($custom-space / 2);
      text-align: center;
    }

    @include breakpoint(large up) {
      padding-top: $l-custom-space;
      padding-bottom: $l-custom-space;
      text-align: left;
    }
    @include breakpoint(large down) {
      padding-top: $global-padding;
      padding-bottom: $global-padding;
    }
    @include breakpoint(1023px down) {
      padding-top: 0;
      h4 {
        line-height: 0;
      }
    }
  }
}

/* ***** CV PAGE ***** */
/* ***** Globals ***** */
.cv {
  padding-bottom: $l-custom-space;
  .grid-container {
    padding-top: rem-calc($global-padding * 2);
    // &:last-child {
    //   padding-top: 0;
    // }
    @include breakpoint(1023px down) {
      padding-top: $global-padding;
    }
  }
  .highlight {
    color: $gold;
  }
  &:not(.pop-up) {
    p {
        padding: 0;
    }
  }
  h3 {
    padding-bottom: $global-padding;
    &::after {
      display: none;
    }
  }
  .time {
    margin-top: $global-margin;
  }
  .ref {
    ul {
      list-style: none;
      margin-left: 0;
    }
  }
  .grid-card {
    @include breakpoint(1023px down) {
      padding-top: 0;
    }
  }
}
