/* ***** Bio ***** */
.bio {
  @include breakpoint(1023px down) {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
  /* ***** Swap position on desktop size ***** */
  @include breakpoint(large up) {
    .portrait {
      order: 1;
    }
    .content {
      order: 2;
    }
  }
  article {
    @include breakpoint(large up) {
      padding-left: rem-calc($custom-space);
    }
    // @include breakpoint(1401px up) {
    //   padding-left: $l-custom-space;
    // }
  }
  figure {
    margin-bottom: $global-margin;
    @include breakpoint(1023px down) {
      margin-top: $global-margin;

    }
    // height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.no-pad {
  padding-bottom: 0;
  margin-bottom: 0;
}
