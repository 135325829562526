/* ***** Hero ***** */
.hero {
  height: calc(100vh);
  .grid-container {
    position: relative;
    .hero-content {
      @include absolute-center;
      width: 100%;
    }
    /* ***** Socials Menu - Bottom ***** */
    .socials {
      position: absolute;
      bottom: rem-calc(10);
      @include breakpoint(medium down) {
        left: $global-padding;
        bottom: $global-padding;
        li {
          // padding-right: $l-custom-space;
          bottom: $custom-space;
        }
      }
      a {
        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
}
