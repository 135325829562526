/* ***** MENU ***** */

/* ***** When Menu is shown on responsive ***** */
.nav-bar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  header {
    padding-left: 0;
    padding-right: 0;
    @include breakpoint(1023px down) {
      &.open {
        height: 100vh;
      }
    }
  }
  &:before {
    display: block;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: $white;
    z-index: 1;
    @include transition-transform(all, 400ms, ease-in-out, 0s);
  }
}

/* ***** Top Navigation Menu and Socials Menu ***** */
.menu {
  position: relative;
  background-color: transparent;
  z-index: 2;
  li {
    padding-right: rem-calc($l-custom-space / 2);
    &:last-child {
      padding-right: 0;
    }
    a {
      position: relative;
      padding-left: 0;
      padding-right: 0;
      /* ***** Underline ***** */
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: rem-calc(1);
        background: $gold;
        opacity: 1;
        @include transition-transform(all, 400ms, ease-in-out, 0s);
      }
      &:hover {
        color: $gold;
        &:after {
          width: 100%;
        }
      }
    }
  }
}

.top-bar {
  background-color: $black;
  padding-right: $global-padding;
  &:before {
    display: block;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: $white;
    z-index: 1;
    @include transition-transform(all, 1000ms, ease-in-out, 0s);
  }
  /* ***** On Mobile Screen ***** */
  @include breakpoint(1023px down) {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100vh;
    // z-index: 1;
    transform: translate(-50%, -50%) translate3d(150%, 50%, 0);
    @include transition-transform(all, 400ms, ease-in-out, 0s);
    .top-bar-right {
      margin-left: auto;
      margin-right: auto;
      .menu {
        transform: translateX(100%);
        @include transition-transform(all, 400ms, ease-in-out, 0s);
        li {
          display: block;
          width: 100%;
          text-align: center;
          padding-right: 0;
          padding-bottom: $global-padding;
          opacity: 0;
          // &.open {
          //   opacity: 1;
          // }
          a {
            width: rem-calc(200);
            margin: auto;
            font-size: rem-calc(40);
            color: $black;
            &:hover {
              &:after {
                width: 0;
              }
            }
          }
        }
      }
    }
    /* ***** When toggle open ***** */
    &.open {
      background-color: $white;
      @include transition-transform(all, 400ms, ease-in-out, 0s);
      transform: translate3d(0, 0, 0);
      width: 100%;
      padding: 0;
      @include breakpoint(1023px down) {
        display: flex!important;
      }
      .menu {
        transform: translateX(0%);
        li {

        }
      }
    }
  }
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* ***** Hamburger Icon ***** */
.title-bar {
  @include breakpoint(1023px down) {
    display: block!important;
  }
  @include breakpoint(medium up) {
    display: none;
  }
  .menu-icon {
    position: relative;
    width: rem-calc(50);
    margin-top: rem-calc(10);
    margin-right: rem-calc(-5);
    padding: $global-padding;
    cursor: pointer;
    z-index: 3;
    outline: none;
  }
  .title-bar-title {
    position: absolute;
    right: $global-padding;
    top: $custom-space;
    width: rem-calc(40);
    background-color: $white;
    padding: rem-calc(1);
    cursor: pointer;
    @include transition-transform(all, 400ms, ease-in-out, 0s);
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      right: $global-padding;
      width: $custom-space;
      background-color: $white;
      padding: rem-calc(1);
      @include transition-transform(all, 400ms, ease-in-out, 0s);
      z-index: 2;
      overflow: visible;
      &.active {
        background-color: $gold;
      }
    }
    &:before {
      top: rem-calc(20);
    }
    &:after {
      top: rem-calc(40);
    }
    /* ***** When toggle open ***** */
    &.open {
      .top-bar,
      .top-bar-right {
        background-color: $white;
      }
      .menu-icon {
        transform: rotate(180deg);
      }
      .title-bar-title {
        opacity: 0;
      }
      &:before,
      &:after {
        background-color: $gold;
        top: rem-calc(30);
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
