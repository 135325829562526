.react {
  .content {
    order: 1;
    padding-right: $custom-space;
    @include breakpoint(1023 down) {
      padding-right: 0;
    }
    // article {
    //   @include breakpoint(large up) {
    //     padding-top: rem-calc($global-padding * 2);
    //     padding-bottom: rem-calc($global-padding * 2);
    //   }
      // @include breakpoint(1441px up) {
      //   padding-top: $l-custom-space;
      //   padding-bottom: $l-custom-space;
      // }
    // }
  }
  .carousel {
    order: 2;
  }

.react-card {
  .item {
    padding: 0;
    margin-bottom: 0;
  }
  .slick-list {
    padding: 0 !important;
    .slick-slide {
      .item {
        position: relative;
      }
    }
  }
}
  .slick-dots {
    list-style: none;
    text-align: center;
    li {
      display: inline-block;
      padding-top: rem-calc($global-padding / 2);
      padding-right: $global-padding;
      button {
        position: relative;
        border: 1px solid $gold;
        background-color: $gold;
        width: rem-calc(12);
        height: rem-calc(12);
        font-size: 0;
        border-radius: rem-calc(25);
        cursor: pointer;
        &::before {
          content: '';
          @include absolute-center;
          width: 100%;
          height: 100%;
          background-color: $black;
          border: 1px solid $gold;
          border-radius: rem-calc(25);
        }
      }
      &.slick-active {
        button {
          &::before {
            background-color: $gold;
          }
        }
      }
    }
  }
}
