/* ***** Footer - Contact ***** */
.contact {
  .grid-container {
    /* ***** Socials Menu ***** */
    .socials {
      position: static;
      display: block;
      padding-top: rem-calc(44);
      .menu {
        align-items: center;
        justify-content: center;
        a {
          padding-top: 0;
        }
      }
      @include breakpoint(medium down) {
        padding-top: $custom-space;
        padding-bottom: $custom-space;
      }
    }

    /* ***** Form ***** */
    form {
      background-color: $medium-gray;
      max-width: calc(100% / 12 * 6);
      padding: rem-calc($custom-space * 2);
      margin: auto;
      border-radius: rem-calc(5);
      /* ***** Input ***** */
      [type='text'],
      [type='textarea'] {
        width: 100%;
        background-color: $medium-gray;
        margin-bottom: rem-calc($custom-space / 2);
        padding-left: rem-calc($custom-space / 2);
        border: none;
        border-bottom: 1px solid $gold;
        border-radius: 0;
        color: $white ;
        &:focus,
        &:active {
          background-color: lighten($gold, 9.5);
          border: 1px solid $gold;
          border-radius: rem-calc(5);
        }
      }
      [type='textarea'] {
        height: rem-calc(100);
        padding-bottom: $custom-space;
      }
      @include breakpoint(1023px down) {
        // background-color: $black;
        max-width: 100%;
      }
      .button {
        margin-top: $global-margin;
        margin-bottom: 0;
      }
      input:-webkit-autofill {
        background-color: lighten($gold, 9.5) !important;
      }
      /* ***** Errors ***** */
      span {
        p {
          color: $gold;
          font-weight: bold;
          padding-top: rem-calc($custom-space);
          padding-bottom: 0;
          padding-left: rem-calc($custom-space / 2);
          margin-bottom: 0;
        }
      }
    }
  }
}
