/* ***** Scroll Down ***** */
.scrollbar {
    > a {
    position: absolute;
    bottom: rem-calc(70);
    right: rem-calc(-42);
    transform: rotate(90deg);
    font-size: rem-calc(14);
    @include transition-transform(all, 400ms, ease-in-out, 0s);
    @include breakpoint(1441px down) {
      right: rem-calc(-22);
    }
    @include breakpoint(1023px down) {
      right: rem-calc(-23);
      font-size: rem-calc(13);
    }
    &::before {
      content: '';
      top: rem-calc(-58);
      right: rem-calc(185);
      width: rem-calc(2);
      height: rem-calc(140);
      transform: rotate(90deg);
      background-color: $white;
      position: absolute;
      @include transition-transform(all, 400ms, ease-in-out, 0s);
      @include breakpoint(medium down) {
        height: rem-calc(80);
        top: rem-calc(-29);
        right: rem-calc(150);
      }
    }
    &:hover {
      bottom: rem-calc(65);
      color: $gold;
      &::before {
        background-color: $gold;
      }
    }
  }
}

/* ***** Arrow - Scroll Up ***** */
.grid-container {
  position: relative;
  .scrollup,
  .back-to {
    opacity: 0;
    @include transition-transform(all, 400ms, ease-in-out, 0s);
    /* ***** Arrow Up ***** */
    .arrow {
      border: 1px solid $gold;
      position: fixed;
      right: rem-calc(20);
      width: rem-calc(60);
      height: rem-calc(60);
      z-index: 10;
      border-radius: rem-calc(30);
      @include transition-transform(all, 400ms, ease-in-out, 0s);
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        border: 2px solid $gold;
        height: rem-calc(18);
        width: rem-calc(18);
        border-left: 0;
        border-bottom: 0;
        @include transition-transform(all, 400ms, ease-in-out, 0s);
      }
    }
  }
  .scrollup {
    .arrow {
      bottom: rem-calc(30);
      &:after {
        margin-left: rem-calc(-9);
        margin-top: rem-calc(-5);
        transform: rotate(-45deg);
      }
      &:hover {
        &::after {
          margin-top: rem-calc(-10);
        }
      }
    }
  }
  .back-to {
    opacity: 1;
    .arrow {
      top: rem-calc(30);
      &:after {
        margin-left: rem-calc(-5);
        margin-top: rem-calc(-8);
        transform: rotate(-135deg);
      }
      &:hover {
        &::after {
          margin-left: rem-calc(-10);
        }
      }
    }
  }
}
