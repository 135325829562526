@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;
@import 'global/mixins.scss';
// @import 'global/false';
@import 'global/typography.scss';
@import 'global/custom.scss';
// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
// @include foundation-label;
// @include foundation-progress-bar;
@include foundation-slider;
// @include foundation-switch;
// @include foundation-table;
// Basic components
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-callout;
@include foundation-card;
// @include foundation-dropdown;
// @include foundation-pagination;
// @include foundation-tooltip;

@import 'components/navigation.scss';
@import 'components/hero.scss';
@import 'components/card.scss';
@import 'components/react.scss';
@import 'components/bio.scss';
@import 'components/footer.scss';

// Containers
// @include foundation-accordion;
// @include foundation-media-object;
// @include foundation-orbit;
@include foundation-responsive-embed;
// @include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-accordion-menu;
// @include foundation-drilldown-menu;
// @include foundation-dropdown-menu;

// Layout components
// @include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
// @include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;
@import 'libraries/slick.scss';
@import 'util/icons.scss';
@import 'util/animations.scss';

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');
